@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  font-family: 'Poppins', sans-serif;
}
body{
  
  background-color: black;
    
    
}
/* html, body {
  overflow-x: hidden; 
  overflow-y: auto;
  max-width: 100vw; 
} */

.sandwich {
    background-image: 
      /* linear-gradient(to bottom right, rgba(29, 78, 216, 0.5) 10%, rgba(29, 78, 216, 0.3) 30%, rgba(0, 0, 0, 0.7) 70%),  */
      url('/public/bg2.svg');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
  }
/* Blinking dot animation */
.textg{
    color: #4CE6A6
}
@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  /* Apply the animation to the dot */
  .blink-dot {
    animation: blink 2s infinite, colorChange 1s forwards;
  }
  
  /* Text transition styles */
  .transition-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
    width: 0;
    opacity: 0;
  }
  
  /* When hovered, expand the width and make it visible */
  .expanded1 {
    width: 40px; /* Adjust based on the length of 'devBranch' */
    opacity: 1;
  }
  
  /* Initially collapsed state (shows 'dB') */
  .collapsed1 {
    width: 15px; /* Adjust based on the length of 'dB' */
    opacity: 1;
  }
  
  .expanded {
    width: 80px; /* Adjust based on the length of 'devBranch' */
    opacity: 1;
  }
  
  /* Initially collapsed state (shows 'dB') */
  .collapsed {
    width: 15px; /* Adjust based on the length of 'dB' */
    opacity: 1;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Start from the initial position */
    }
    100% {
      transform: translateX(calc(-250px * 9 )); /* Move left to show the second half */
    }
  }
  .animate-scroll {
    animation: scroll 30s linear infinite;
    animation-play-state: running; /* Ensure animation runs by default */
  }
  
  .slider:hover {
    animation-play-state: paused; /* Pause the animation on hover */
  }
  
  @keyframes scrollright {
    0% {
      transform: translateX(calc(-250px * 9)); /* Start from the far left */
    }
    100% {
      transform: translateX(0); /* Move to the right, back to the initial position */
    }
  }
  
  .gradient-overlay {
    background: linear-gradient(to left, black, transparent, black);
    background-size:100% 500% 100%; /* Controls the size of the gradient */
  }
  .animate-scrollright {
    animation: scrollright 20s linear infinite;
    animation-play-state: running; /* Ensure animation runs by default */
  }
  
  

/* Hide Scrollbar but allow scrolling */
::-webkit-scrollbar {
  width: 0px;
}


@layer utilities{
  .paused{
    animation-play-state: paused;
  }
}


/* Reviews Card */




.glass-card {
  background: rgba(255, 255, 255, 0.1); /* Glassmorphism effect */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  position: relative;
  overflow: hidden; /* Ensures glow stays within the card */
}

.glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  pointer-events: none; /* Disable interaction on the glow */
  transition: background 0.1s ease;
}

*{
  -webkit-tap-highlight-color: transparent;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out forwards;
}

.animate-fadeOut {
  animation: fadeOut 4s ease-out forwards;
}

.animate-zoomIn {
  animation: zoomIn 1.5s ease-out forwards;
}
